<template>
  <section>
    <div class="max-w-7xl mx-auto px-6 sm:px-8">

        <!-- Carousel built with Swiper.js [https://swiperjs.com/] -->
        <!-- * Custom styles in src/css/additional-styles/theme.scss -->
        <div class="relative before:absolute before:inset-0 before:-translate-x-full before:z-20 before:bg-gradient-to-l before:from-transparent before:to-zinc-950 before:to-10% after:absolute after:inset-0 after:translate-x-full after:z-20 after:bg-gradient-to-r after:from-transparent after:to-zinc-950 after:to-10%">
          <div class="testimonials-carousel swiper-container group">
            <Highlighter class="swiper-wrapper w-fit" :refresh="swiperInitialized">
              <!-- Carousel items -->
                <HighlighterItem v-for="item in items" :key="item.name" class="swiper-slide h-auto group/slide">
                    <div class="relative h-full bg-zinc-925 rounded-[inherit] z-20 overflow-hidden">
                        <!-- Radial gradient -->
                        <div class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
                            <div class="absolute inset-0 translate-z-0 rounded-full bg-zinc-800 group-[.swiper-slide-active]/slide:bg-emerald-600 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                        </div>
                        <div class="flex flex-col p-6 h-full">
<!--                            <img class="mb-3" src="../images/carousel-icon-01.svg" width="56" height="56" alt="Icon 01">-->
                            <div class="h-14 w-14 mb-4 rounded-full bg-gradient-to-b from-zinc-600 to-zinc-800 p-[1px]">
                                <component :is="item.icon" class="rounded-full bg-zinc-900 p-4 h-full w-full text-zinc-100" />
                            </div>
                            <div class="grow">
                                <div class="text-zinc-100 font-bold text-lg mb-1">{{ item.name }}</div>
                                <div class="text-zinc-400 mb-3">{{ item.description }}</div>
                            </div>
                            <div v-if="item.href" class="text-right">
                                <a class="text-sm font-medium text-zinc-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" href="#0">Learn More <span class="tracking-normal text-emerald-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span></a>
                            </div>
                        </div>
                    </div>
                </HighlighterItem>
            </Highlighter>
          </div>
        </div>

        <!-- Arrows -->
        <div class="flex mt-8 justify-end">
          <button class="carousel-prev relative z-20 w-12 h-12 flex items-center justify-center group">
            <span class="sr-only">Previous</span>
            <svg class="w-4 h-4 fill-zinc-600 group-hover:fill-emerald-500 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
            </svg>
          </button>
          <button class="carousel-next relative z-20 w-12 h-12 flex items-center justify-center group">
            <span class="sr-only">Next</span>
            <svg class="w-4 h-4 fill-zinc-600 group-hover:fill-emerald-500 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
            </svg>
          </button>
        </div>

    </div>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import Particles from './Particles.vue'
import Highlighter from './Highlighter.vue'
import HighlighterItem from './HighlighterItem.vue'

// Import Swiper
import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import {
    BuildingLibraryIcon, QueueListIcon, SparklesIcon, FingerPrintIcon, LinkIcon,
    LockClosedIcon, BuildingStorefrontIcon, ArrowPathIcon, CheckCircleIcon, BoltIcon
} from "@heroicons/vue/24/outline";
Swiper.use([Navigation])

export default {
  name: 'TestimonialsCarousel',
  components: {
    Particles,
    Highlighter,
    HighlighterItem,
  },
    props: {
      items: {
        type: Array,
        required: false,
          default: [
              {
                  name: 'Supporting over 2400 banks',
                  description: 'Synci is fully integrated with GoCardless, supporting over 2400 banks and financial institutions across 31 countries in Europe.',
                  icon: BuildingLibraryIcon,
              },
              {
                  name: 'Powerful customization',
                  description: 'Synci offers many options to customize how your transactions are synced. Such as custom field mapping, amount swapping, and regex for payee and memo manipulation. If your bank is hard to work with, Synci is the solution.',
                  icon: BoltIcon,
              },
              {
                  name: 'Pending transactions',
                  description: 'Sync pending transactions to get an immediate overview of your budget. Synci will automatically update the transaction in YNAB once it has been cleared by your bank.',
                  icon: CheckCircleIcon,
              },
              {
                  name: 'Strong security',
                  description: 'We use a secure Open Banking solution to fetch data, and all keys and tokens are encrypted using the AES-256-CBC cipher. You can also protect your account with two-factor authentication.',
                  icon: LockClosedIcon,
              },
              {
                  name: 'Completely automatic',
                  description: 'Synci automatically transfers transactions from your bank to your budget. Depending on your bank, transactions are synced at least every 6 hours.',
                  icon: ArrowPathIcon,
              },
              {
                  name: 'Privacy focused',
                  description: 'Enable Transfer-only Mode to automatically delete transactions from Synci, once they have been transferred. Additionally, we do not share any of your data with undisclosed third parties.',
                  icon: FingerPrintIcon,
              },
              {
                  name: 'Extensive logging',
                  description: 'If Transfer-only Mode is turned off, Synci logs everything that happens with each of your transactions. Giving you full control of when and how your transactions were synced.',
                  icon: QueueListIcon,
              },
              {
                  name: 'Easy setup',
                  description: 'Setting up new budget or bank connections is quick and easy. To connect your accounts, you only need to select your bank and securely log in with your online banking credentials.',
                  icon: SparklesIcon,
              },
          ],
      },
    },
  setup() {

    const swiperInitialized = ref(false)

    onMounted(() => {
      const carousel = new Swiper('.testimonials-carousel', {
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        },
        grabCursor: true,
        loop: false,
        centeredSlides: false,
        initialSlide: 0,
        spaceBetween: 24,
        navigation: {
          nextEl: '.carousel-next',
          prevEl: '.carousel-prev',
        },
      })
      swiperInitialized.value = true
    })

    return {
      swiperInitialized,
    }
  }
}
</script>
